import { useState } from 'react'
import {
  Autocomplete,
  Box,
  FormControl,
  IconButton,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material'
import { Edit as EditIcon, Trash2 as TrashIcon } from 'react-feather'

import { AddFieldDialog } from '../'
import { DeleteFieldDialog } from './components'

import { QuestionType } from './question.types'

import { useDataProcessQuestionTemplate, useSnackbar } from 'hooks'

import service from 'service'
import { formatters } from 'helpers'

const Question = ({ dataProcessTemplateQuestion, refresh }: QuestionType) => {
  const [openEditFieldDialog, setOpenEditFieldDialog] = useState(false)
  const [openDeleteFieldDialog, setOpenDeleteFieldDialog] = useState(false)
  const [optionSelect, setOptionSelect] = useState('')

  const { snackbar } = useSnackbar()
  const { dataProcessTemplate } = useDataProcessQuestionTemplate()

  const deleteQuestion = async () => {
    try {
      await service.dponet.dataProcessTemplateQuestion.destroy({
        dataProcessTemplateId: dataProcessTemplate?.id,
        dataProcessTemplateQuestionId: dataProcessTemplateQuestion?.id,
      })
      snackbar.open({
        message: 'Campo excluído!',
        variant: 'success',
      })
      setOpenDeleteFieldDialog(false)
      refresh()
    } catch (error) {
      snackbar.open({
        message:
          formatters.errorMessage(error) ||
          'Ocorreu um erro ao tentar excluir o campo',
        variant: 'error',
      })
    }
  }

  const onCloseEditDialog = () => {
    refresh()
    setOpenEditFieldDialog(false)
  }

  const handleChange = (event: SelectChangeEvent) => {
    setOptionSelect(event.target.value as string)
  }

  return (
    <Paper variant="outlined" component={Box} p={6}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="body1" color="primary" fontWeight="bold">
          {dataProcessTemplateQuestion.title}
        </Typography>

        {dataProcessTemplate?.owner && (
          <Box display="flex" gap={1}>
            <IconButton>
              <EditIcon
                size={20}
                onClick={() => setOpenEditFieldDialog(true)}
              />
            </IconButton>
            <IconButton>
              <TrashIcon
                size={20}
                onClick={() => setOpenDeleteFieldDialog(true)}
              />
            </IconButton>
          </Box>
        )}
      </Box>
      <Typography variant="subtitle2" color="text.secondary" gutterBottom>
        {dataProcessTemplateQuestion.description}
      </Typography>
      {dataProcessTemplateQuestion.fieldType === 'single_select' ? (
        <FormControl fullWidth>
          <Select value={optionSelect} onChange={handleChange}>
            {dataProcessTemplateQuestion?.dataProcessTemplateQuestionOptions?.map(
              (option, index) => (
                <MenuItem value={index} key={index}>
                  <Typography variant="body2" color="text.secondary">
                    {option.option}
                  </Typography>
                </MenuItem>
              )
            )}
          </Select>
        </FormControl>
      ) : dataProcessTemplateQuestion.fieldType === 'multi_select' ? (
        <Autocomplete
          multiple
          id="tags-standard"
          options={
            dataProcessTemplateQuestion?.dataProcessTemplateQuestionOptions ||
            []
          }
          getOptionLabel={(option) => option.option}
          defaultValue={[]}
          renderInput={(params) => <TextField {...params} variant="outlined" />}
        />
      ) : (
        <TextField fullWidth variant="outlined" disabled />
      )}
      <AddFieldDialog
        open={openEditFieldDialog}
        onClose={onCloseEditDialog}
        step={dataProcessTemplateQuestion.step}
        refresh={refresh}
        dataProcessTemplateQuestion={dataProcessTemplateQuestion}
        edit={true}
      />
      <DeleteFieldDialog
        open={openDeleteFieldDialog}
        onClose={() => setOpenDeleteFieldDialog(false)}
        onSave={deleteQuestion}
      />
    </Paper>
  )
}

export default Question
