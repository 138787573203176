import { TableBody, TableContainer, TableHead } from '@mui/material'

import { Table as TableComponent, TableRow, MUI } from 'components'

import { LinkedCompaniesTableType } from './linkedCompaniesTable.types'

const LinkedCompaniesTable = ({
  linkedCompanies,
}: LinkedCompaniesTableType) => {
  return (
    <TableContainer>
      <TableComponent>
        <TableHead>
          <TableRow hover={false}>
            <MUI.TableCell width="10%">Identificador</MUI.TableCell>
            <MUI.TableCell width="50%">Empresa</MUI.TableCell>
            <MUI.TableCell width="40%">Representante</MUI.TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {linkedCompanies.map((company, index) => (
            <TableRow key={index} hover={false}>
              <MUI.TableCell>{company.id}</MUI.TableCell>
              <MUI.TableCell>{company.name}</MUI.TableCell>
              <MUI.TableCell>{company.responsibleName}</MUI.TableCell>
            </TableRow>
          ))}
        </TableBody>
      </TableComponent>
    </TableContainer>
  )
}

export default LinkedCompaniesTable
