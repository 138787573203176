import { Grid, Select, TextField } from '@mui/material'

import { Card, LabelDescription } from 'components'
import { Questions } from 'views/DataProcessTemplateQuestion/components'

const DataCollectedStep = () => {
  return (
    <Card title="Dados tratados">
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <LabelDescription title="Categoria do titular" />
          <Select fullWidth disabled variant="outlined" value="" />
        </Grid>
        <Grid item xs={12}>
          <LabelDescription title="Faixa etária" />
          <Select fullWidth disabled variant="outlined" value="" />
        </Grid>
        <Grid item xs={12}>
          <LabelDescription title="Quantidade aproximada de titulares" />
          <TextField fullWidth disabled variant="outlined" />
        </Grid>
        <Grid item xs={12}>
          <Questions step="data_collected" />
        </Grid>
      </Grid>
    </Card>
  )
}

export default DataCollectedStep
