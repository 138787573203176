import { useContext } from 'react'

import { DataProcessTemplateQuestionContext } from 'contexts'

const useDataProcessQuestionTemplate = () => {
  const context = useContext(DataProcessTemplateQuestionContext)
  if (context === undefined) {
    throw new Error(
      'useDataProcessQuestionTemplate must be used within a DataProcessTemplateQuestionContext.Provider'
    )
  }

  return context
}

export default useDataProcessQuestionTemplate
