import api from 'service/api'

const dponetAPI = api.create('dponet')

const get = async ({
  dataProcessTemplateId = '',
  params,
}: {
  dataProcessTemplateId?: string | number
  params?: object
}) => {
  return await dponetAPI.get(
    `/advisors/data_processes/templates/${dataProcessTemplateId}/questions`,
    { params }
  )
}

const create = async ({
  dataProcessTemplateId = '',
  data,
}: {
  dataProcessTemplateId?: string | number
  data?: object
}) => {
  return await dponetAPI.post(
    `/advisors/data_processes/templates/${dataProcessTemplateId}/questions`,
    data
  )
}

const update = async ({
  dataProcessTemplateId = '',
  dataProcessTemplateQuestionId = '',
  data,
}: {
  dataProcessTemplateId?: string | number
  dataProcessTemplateQuestionId?: string | number
  data: object
}) => {
  return await dponetAPI.put(
    `/advisors/data_processes/templates/${dataProcessTemplateId}/questions/${dataProcessTemplateQuestionId}`,
    data
  )
}

const destroy = async ({
  dataProcessTemplateId = '',
  dataProcessTemplateQuestionId = '',
}: {
  dataProcessTemplateId?: string | number
  dataProcessTemplateQuestionId?: string | number
}) => {
  return await dponetAPI.delete(
    `/advisors/data_processes/templates/${dataProcessTemplateId}/questions/${dataProcessTemplateQuestionId}`
  )
}

export default {
  create,
  destroy,
  get,
  update,
}
