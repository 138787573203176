import { Card as CardMaterial, Divider, Typography } from '@mui/material'

import { CardContentBox, CardTitleBox } from './components'

import { CardType } from './card.types'

const Card = ({ title, children, action }: CardType) => {
  return (
    <CardMaterial>
      <CardTitleBox>
        <Typography variant="body1" fontWeight={700}>
          {title}
        </Typography>

        {action}
      </CardTitleBox>
      <Divider />
      <CardContentBox>{children}</CardContentBox>
    </CardMaterial>
  )
}

export default Card
