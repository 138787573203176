import * as yup from 'yup'

const schema = yup.object({
  requiredField: yup.boolean().required(),
  title: yup.string().required(),
  description: yup.string().required(),
  fieldType: yup.string().required(),
  option: yup.string(),
})

export type AddFieldDialogSchemaType = yup.InferType<typeof schema>

export default schema
