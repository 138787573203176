import { Grid, styled } from '@mui/material'

const Container = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    display: 'flex',
    flexDirection: 'column-reverse',
  },
}))

export default Container
