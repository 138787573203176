import api from 'service/api'

const dponetAPI = api.create('dponet')

const get = async ({
  dataProcessTemplateId = '',
  params,
}: {
  dataProcessTemplateId?: string | number
  params?: object
}) => {
  return await dponetAPI.get(
    `/advisors/data_processes/templates/${dataProcessTemplateId}`,
    { params }
  )
}

const create = async (data: object) => {
  return await dponetAPI.post('/advisors/data_processes/templates', data)
}

const update = async ({
  dataProcessTemplateId = '',
  data,
}: {
  dataProcessTemplateId?: string | number
  data: object
}) => {
  return await dponetAPI.put(
    `/advisors/data_processes/templates/${dataProcessTemplateId}`,
    data
  )
}

const destroy = async ({
  dataProcessTemplateId = '',
}: {
  dataProcessTemplateId?: string | number
}) => {
  return await dponetAPI.delete(
    `/advisors/data_processes/templates/${dataProcessTemplateId}`
  )
}

export default {
  create,
  destroy,
  get,
  update,
}
