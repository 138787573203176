import { Button, TableBody, TableContainer, TableHead } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { reverse } from 'named-urls'

import { Card, MUI, Table as TableComponent, TableRow } from 'components'

import { MappingType } from './mapping.types'

import routes from 'constants/routes'

const Mapping = ({ dataProcessTemplate, form }: MappingType) => {
  const navigate = useNavigate()

  const addedFields =
    form === 'create' ? 0 : dataProcessTemplate?.totalQuestions || 0
  const mappingType = addedFields === 0 ? 'Padrão' : 'Personalizado'

  const handleRedirectToEdit = () => {
    navigate(
      reverse(routes.app.dataProcesses.templates.questions.edit, {
        dataProcessTemplateId: dataProcessTemplate?.id || '',
      })
    )
  }

  return (
    <Card
      title="Mapeamento"
      action={
        <Button
          variant="outlined"
          onClick={handleRedirectToEdit}
          disabled={form === 'create'}
        >
          {dataProcessTemplate?.owner ? 'Editar' : 'Visualizar'} Modelo
        </Button>
      }
    >
      <TableContainer>
        <TableComponent aria-label="listagem de tickets">
          <TableHead>
            <MUI.TableCell>Tipo do Mapeamento</MUI.TableCell>
            <MUI.TableCell>Campos Adicionados</MUI.TableCell>
          </TableHead>
          <TableBody>
            <TableRow hover={false}>
              <MUI.TableCell>{mappingType}</MUI.TableCell>
              <MUI.TableCell>{addedFields}</MUI.TableCell>
            </TableRow>
          </TableBody>
        </TableComponent>
      </TableContainer>
    </Card>
  )
}

export default Mapping
