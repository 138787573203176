import * as yup from 'yup'

const schema = yup.object({
  allOrganizationCompanies: yup.boolean().required(),
  organizationCompanies: yup
    .array()
    .of(
      yup.object().shape({
        label: yup.string().required(),
        document: yup.string().required(),
        companyId: yup.number().required(),
      })
    )
    .when('allOrganizationCompanies', {
      is: false,
      then: () =>
        yup
          .array()
          .of(
            yup.object().shape({
              label: yup.string().required(),
              document: yup.string().required(),
              companyId: yup.number().required(),
            })
          )
          .min(1, 'Selecione ao menos uma empresa')
          .required(),
    }),
})

export type CompaniesLinkingFormSchemaType = yup.InferType<typeof schema>

export default schema
