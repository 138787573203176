import {
  Avatar,
  Box,
  Button,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Tooltip,
  Typography,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { reverse } from 'named-urls'
import { ArrowRight } from 'react-feather'
import { isEmpty } from 'lodash'

import {
  Table as TableComponent,
  TableRow,
  LinearProgressWithLabel,
  MUI,
} from 'components'

import { CompanyTableType } from './companyTable.types'
import { TagMinimumType } from 'types/tag.types'

import organizationCompaniesHelper from 'helpers/organizationCompanies'
import routes from 'constants/routes'
import { getBaseURL } from 'service/env'

const CompanyTable = ({ organizationCompanies }: CompanyTableType) => {
  const navigate = useNavigate()
  const baseURL = getBaseURL('dponet')

  const handleCLickTableRow = (id: number) => {
    navigate(
      reverse(routes.app.companies.update, {
        organizationCompanyId: id,
      })
    )
  }

  const mountTagNames = (tags: TagMinimumType[]) => {
    if (isEmpty(tags)) return '-'

    return tags.map((organizationTag) => organizationTag.name).join(', ')
  }

  return (
    <TableContainer>
      <TableComponent aria-label="listagem das empresas">
        <TableHead>
          <TableRow>
            <TableCell align="left" width="12.5%">
              Empresa
            </TableCell>
            <TableCell align="left" width="12.5%">
              Vínculo
            </TableCell>
            <TableCell align="left" width="20%">
              Classificadores
            </TableCell>
            <TableCell align="left" width="20%">
              Requisitos Fundamentais
            </TableCell>
            <TableCell align="left" width="20%">
              Conformidade dos Questionários
            </TableCell>
            <TableCell align="left" width="20%">
              Health Score
            </TableCell>
            {/* <TableCell align="left" width="5%">
              Etapas
            </TableCell> */}
            <TableCell align="center" width="5%">
              Ações
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {organizationCompanies?.map((organizationCompany) => {
            const tagNames = mountTagNames(organizationCompany.organizationTags)

            return (
              <TableRow
                key={organizationCompany.id}
                onClick={() => handleCLickTableRow(organizationCompany.id)}
              >
                <TableCell align="left">
                  <Box display="flex" gap={5}>
                    <Avatar
                      alt={organizationCompany?.company?.name}
                      src={`${baseURL}${organizationCompany?.company?.logo?.url}`}
                      variant="square"
                    />
                    <Box display="flex" flexDirection="column">
                      <Typography variant="body2">
                        {organizationCompany?.company?.name}
                      </Typography>
                      <Typography variant="subtitle2" color="text.secondary">
                        {organizationCompany?.company?.document}
                      </Typography>
                    </Box>
                  </Box>
                </TableCell>
                <TableCell align="left">
                  {organizationCompany.companyMatrix ? (
                    <Box display="flex" gap={5}>
                      {organizationCompany?.companyMatrix && (
                        <Avatar
                          alt={organizationCompany?.companyMatrix?.networkName}
                          src={`${baseURL}${organizationCompany?.companyMatrix?.logo?.url}`}
                          variant="square"
                        />
                      )}
                      <Box display="flex" flexDirection="column">
                        <Typography variant="body2">
                          {organizationCompany.companyMatrix?.networkName}
                        </Typography>
                        <Typography variant="subtitle2" color="text.secondary">
                          {organizationCompany.companyMatrix?.document}
                        </Typography>
                      </Box>
                    </Box>
                  ) : (
                    <Typography textAlign="center">-</Typography>
                  )}
                </TableCell>
                <TableCell align="left">
                  {!isEmpty(organizationCompany?.organizationTags) ? (
                    <Tooltip title={tagNames}>
                      <Box
                        display="flex"
                        whiteSpace="nowrap"
                        textOverflow="ellipsis"
                        overflow="hidden"
                        maxWidth="15vw"
                      >
                        {organizationCompany?.organizationTags.map((tag) => (
                          <Box mx={1} key={tag.id} display="flex">
                            <MUI.Chip
                              label={tag?.name}
                              size="small"
                              variant="filled"
                            />
                          </Box>
                        ))}
                      </Box>
                    </Tooltip>
                  ) : (
                    <Typography textAlign="center">-</Typography>
                  )}
                </TableCell>
                <TableCell align="left">
                  <LinearProgressWithLabel
                    thickness={3}
                    borderRadius={1}
                    color={organizationCompaniesHelper.complianceColor(100)}
                    value={100}
                  />
                </TableCell>
                <TableCell align="left">
                  <LinearProgressWithLabel
                    thickness={3}
                    borderRadius={1}
                    color={organizationCompaniesHelper.complianceColor(
                      organizationCompany?.company?.gapCompliance
                    )}
                    value={organizationCompany?.company?.gapCompliance}
                  />
                </TableCell>
                <TableCell align="left">
                  <Box minWidth={206}>
                    <LinearProgressWithLabel
                      thickness={3}
                      borderRadius={1}
                      color={organizationCompaniesHelper.complianceColor(
                        organizationCompany?.company?.healthScore
                      )}
                      value={organizationCompany?.company?.healthScore}
                    />
                  </Box>
                </TableCell>
                {/* <TableCell align="center">
                  {organizationCompany?.company?.companyTrails && (
                    <Typography>
                      {
                        organizationCompany?.company?.companyTrails?.filter(
                          (trail) => trail.completed === true
                        ).length
                      }
                      &nbsp;/&nbsp;
                      {organizationCompany?.company?.companyTrails.length}
                    </Typography>
                  )}
                </TableCell> */}
                {/* <TableCell align="center" width="10%">
                  <Chip
                    label={organizationCompaniesHelper.statusTranslation(
                      organizationCompany.status
                    )}
                    color={organizationCompaniesHelper.statusColor(
                      organizationCompany.status
                    )}
                    size="small"
                    variant="filled"
                  />
                </TableCell> */}
                <TableCell align="right" width="10%">
                  <Button
                    variant="outlined"
                    color="neutral"
                    endIcon={<ArrowRight size={20} />}
                  >
                    DETALHES
                  </Button>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </TableComponent>
    </TableContainer>
  )
}

export default CompanyTable
