import { ListPageStructure, PageHead } from 'components'
import { useParams } from 'react-router-dom'
import { Box, Skeleton } from '@mui/material'

import { Steps } from './components'

import { DataProcessTempleteQuestionProvider } from 'providers'

import { DataProcessTemplateType } from 'types/dataProcessTemplate.types'

import { useFetch } from 'hooks'

import service from 'service'

const DataProcessTemplateEdit = () => {
  const { dataProcessTemplateId } = useParams()

  const { response, loading } = useFetch(
    service.dponet.dataProcessTemplate.get,
    {
      dataProcessTemplateId,
      params: {
        minimal: true,
      },
    }
  )

  const dataProcessTemplate: DataProcessTemplateType =
    response?.data?.dataProcessTemplate

  return (
    <ListPageStructure>
      <PageHead name="Edição do Modelo" />

      {loading ? (
        <Box gap={4} display="flex" flexDirection="column">
          <Skeleton height={65} variant="rounded" />
          <Skeleton height={500} variant="rounded" />
          <Skeleton height={35} variant="rounded" />
        </Box>
      ) : (
        <DataProcessTempleteQuestionProvider
          dataProcessTemplate={dataProcessTemplate}
        >
          <Steps />
        </DataProcessTempleteQuestionProvider>
      )}
    </ListPageStructure>
  )
}

export default DataProcessTemplateEdit
