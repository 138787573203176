import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, Grid, TextField } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { Card, LoadingFeedback } from 'components'

import { FormType } from './form.types'

import { useSnackbar } from 'hooks'

import schema, { FormSchemaType } from './schema'
import service from 'service'
import { formatters } from 'helpers'
import { reverse } from 'named-urls'
import routes from 'constants/routes'
import { ConfirmDestroyTemplate } from './components'

const Form = ({ form, dataProcessTemplate }: FormType) => {
  const [loding, setLoading] = useState(false)
  const [openDialogDestroyTemplate, setOpenDialogDestroyTemplate] =
    useState(false)

  const { snackbar } = useSnackbar()
  const navigate = useNavigate()

  const {
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: dataProcessTemplate?.name || '',
    },
  })

  const onSubmit = async (data: FormSchemaType) => {
    try {
      setLoading(true)

      if (form === 'create') {
        await create(data)
      } else {
        await edit(data)
      }
    } catch (error) {
      snackbar.open({
        message: formatters.errorMessage(error),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  const create = async (data: FormSchemaType) => {
    const response = await service.dponet.dataProcessTemplate.create({
      dataProcessTemplate: data,
    })

    const dataProcessTemplateId = response?.data?.dataProcessTemplate?.id

    navigate(
      reverse(routes.app.dataProcesses.templates.edit, {
        dataProcessTemplateId: dataProcessTemplateId,
      })
    )
  }

  const edit = async (data: FormSchemaType) => {
    await service.dponet.dataProcessTemplate.update({
      data: { dataProcessTemplate: data },
      dataProcessTemplateId: dataProcessTemplate?.id,
    })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card
        title="Informações Gerais"
        action={
          <Box display="flex" justifyContent="flex-end" gap={1}>
            {((dataProcessTemplate?.owner && !dataProcessTemplate?.default) ||
              form === 'create') && (
              <>
                {form === 'edit' && (
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => setOpenDialogDestroyTemplate(true)}
                  >
                    Excluir
                  </Button>
                )}
                <Button variant="contained" color="primary" type="submit">
                  Salvar
                </Button>
              </>
            )}
          </Box>
        }
      >
        <LoadingFeedback open={loding} />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors?.name}
                  helperText={errors?.name?.message}
                  label="Nome do Modelo"
                  type="text"
                  fullWidth
                  disabled={form !== 'create' && !dataProcessTemplate?.owner}
                />
              )}
              control={control}
              name="name"
            />
          </Grid>
        </Grid>
      </Card>
      <ConfirmDestroyTemplate
        open={openDialogDestroyTemplate}
        onClose={() => setOpenDialogDestroyTemplate(false)}
        dataProcessTemplateId={dataProcessTemplate?.id}
      />
    </form>
  )
}

export default Form
