import { Grid, Select, TextField } from '@mui/material'

import { Card, LabelDescription } from 'components'
import { Questions } from 'views/DataProcessTemplateQuestion/components'

const DataTreatmentStep = () => {
  return (
    <Card title="Dados compartilhados">
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <LabelDescription
            title="Com quem o dado é compartilhado?"
            description="É o nome do terceiro ou do departamento da organização a quem o registro é compartilhado"
          />
          <Select fullWidth disabled variant="outlined" value="" />
        </Grid>
        <Grid item xs={12}>
          <LabelDescription
            title="Por quê o dado é compartilhado?"
            description="É a finalidade do compartilhamento do registro com o terceiro ou com o departamento da organização"
          />
          <TextField
            fullWidth
            disabled
            variant="outlined"
            multiline
            minRows={3}
          />
        </Grid>
        <Grid item xs={12}>
          <LabelDescription
            title="Como o dado é compartilhado?"
            description="É o mecanismo de compartilhamento do registro, como via pasta, e-mail, pen drive ou sistema de gestão"
          />
          <TextField
            fullWidth
            disabled
            variant="outlined"
            multiline
            minRows={3}
          />
        </Grid>
        <Grid item xs={12}>
          <LabelDescription title="Tipo de compartilhamento" />
          <Select fullWidth disabled variant="outlined" value="" />
        </Grid>
        <Grid item xs={12}>
          <LabelDescription
            title="Este compartilhamento é uma transferência internacional?"
            description={
              'Caso a resposta for "Sim" selecione os paises que é feito o compartilhamento'
            }
          />
          <Select fullWidth disabled variant="outlined" value="" />
        </Grid>
        <Grid item xs={12}>
          <LabelDescription
            title="Para realizar este compartilhamento, você utiliza alguma plataforma, software ou aplicativo?"
            description={
              'Caso a resposta for "Sim" liste os meios de compartilhamentos de dados'
            }
          />
          <Select fullWidth disabled variant="outlined" value="" />
        </Grid>
        <Grid item xs={12}>
          <Questions step="data_treatment" />
        </Grid>
      </Grid>
    </Card>
  )
}

export default DataTreatmentStep
