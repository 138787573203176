import { useState } from 'react'
import { Button, Divider, Grid } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { BaseBox, FormLogin, GreyTypography } from './components'

import routes from 'constants/routes'

const Login = () => {
  const [isLoading, setLoading] = useState<boolean>(false)
  const navigate = useNavigate()

  const goToRegister = () => navigate(routes.auth.register)

  return (
    <BaseBox title="Bem vindo(a)!">
      <Grid item xs={12}>
        <FormLogin isLoading={isLoading} setLoading={setLoading} />
      </Grid>
      <Grid item xs={12}>
        <Divider>
          <GreyTypography variant="caption">ou</GreyTypography>
        </Divider>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="secondary"
          onClick={goToRegister}
          disabled={isLoading}
          fullWidth
        >
          Criar conta
        </Button>
      </Grid>
    </BaseBox>
  )
}

export default Login
