import { Box, Button, Grid } from '@mui/material'
import { Settings as SettingsIcon } from 'react-feather'

import { useSnackbar } from 'hooks'

import service from 'service'
import { getBaseURL } from 'service/env'

const ButtonTransparentLogin = ({
  companyId,
}: {
  companyId: string | number
}) => {
  const { snackbar } = useSnackbar()

  const handleTransparentLogin = async () => {
    try {
      const response = await service.dponet.outsourcedDpo.transparentLogin({
        companyId,
      })

      redirectUserToApp(response?.data?.authToken, response?.data?.cookieToken)
    } catch (error) {
      snackbar.open({
        message: 'Ocorreu um erro ao acessar o login transparente',
        variant: 'error',
      })
    }
  }

  const redirectUserToApp = (authToken: string, cookieToken: string) => {
    const url = `${getBaseURL(
      'dponetPortal'
    )}/outsourced-dpo/${authToken}/${companyId}/${cookieToken}`

    window.open(url, '_blank')
  }

  return (
    <Grid item xs={12} sm={2.5} lg={3}>
      <Box
        display="flex"
        alignItems="center"
        height="100%"
        justifyContent="center"
      >
        <Button
          startIcon={<SettingsIcon size={18} />}
          onClick={handleTransparentLogin}
          variant="outlined"
        >
          Acessar painel do cliente
        </Button>
      </Box>
    </Grid>
  )
}

export default ButtonTransparentLogin
