import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { isUndefined } from 'lodash'

import { DataProcessTemplateQuestionContext } from 'contexts'

import { DataProcessTemplateQuestionProviderType } from './dataProcessTemplateQuestionProvider.types'

import routes from 'constants/routes'
import { reverse } from 'named-urls'

const DataProcessTempleteQuestionProvider = ({
  children,
  dataProcessTemplate,
}: DataProcessTemplateQuestionProviderType) => {
  const navigate = useNavigate()

  const [activeStep, setActiveStep] = useState(0)
  const [lastActiveStep, setLastActiveStep] = useState(0)
  const [wantsToAdvanceStep, setWantsToAdvanceStep] = useState(false)
  const [wantsToReturnStep, setWantsToReturnStep] = useState(false)

  const handleAdvanceStep = () => {
    setWantsToAdvanceStep(true)
  }

  const handleReturnStep = () => {
    setWantsToReturnStep(true)
  }

  const resetAdvanceStep = () => {
    setWantsToAdvanceStep(false)
  }

  const resetReturnStep = () => {
    setWantsToReturnStep(false)
  }

  const handleNext = () => {
    if (activeStep === 3) {
      navigate(
        reverse(routes.app.dataProcesses.templates.edit, {
          dataProcessTemplateId: dataProcessTemplate.id,
        })
      )
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    if (activeStep === 0) {
      navigate(
        reverse(routes.app.dataProcesses.templates.edit, {
          dataProcessTemplateId: dataProcessTemplate.id,
        })
      )
    }

    setLastActiveStep(activeStep - 1)
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleStep = (step?: number) => {
    if (isUndefined(step)) return

    setActiveStep(step)
  }

  const isCompleted = (step?: number) => {
    if (isUndefined(step)) return false

    return lastActiveStep > step
  }

  useEffect(() => {
    if (activeStep > lastActiveStep) {
      setLastActiveStep(activeStep)
    }
  }, [activeStep])

  return (
    <DataProcessTemplateQuestionContext.Provider
      value={{
        activeStep,
        dataProcessTemplate,
        handleAdvanceStep,
        handleBack,
        handleNext,
        handleReturnStep,
        handleStep,
        isCompleted,
        lastActiveStep,
        resetAdvanceStep,
        resetReturnStep,
        setActiveStep,
        wantsToAdvanceStep,
        wantsToReturnStep,
      }}
    >
      {children}
    </DataProcessTemplateQuestionContext.Provider>
  )
}

export default DataProcessTempleteQuestionProvider
