import { createContext } from 'react'

import { DataProcessTemplateQuestionContextType } from 'providers/DataProcessTempleteQuestionProvider/dataProcessTemplateQuestionProvider.types'

const contextValues: DataProcessTemplateQuestionContextType = {
  activeStep: 0,
  lastActiveStep: 0,
  wantsToAdvanceStep: false,
  wantsToReturnStep: false,
  handleAdvanceStep: () => {},
  handleReturnStep: () => {},
  resetAdvanceStep: () => {},
  resetReturnStep: () => {},
  handleNext: () => {},
  handleBack: () => {},
  handleStep: () => {},
  setActiveStep: () => {},
  isCompleted: () => false,
}

const DataProcessTemplateQuestionContext = createContext(contextValues)

export default DataProcessTemplateQuestionContext
