import api from 'service/api'

const dponetAPI = api.create('dponet')

const get = async ({
  dataProcessTemplateId = '',
  params = {},
}: {
  dataProcessTemplateId?: string | number
  params?: object
}) => {
  return dponetAPI.get(
    `/advisors/data_processes/templates/${dataProcessTemplateId}/companies`,
    {
      params,
    }
  )
}

const create = async ({
  dataProcessTemplateId = '',
  data,
}: {
  dataProcessTemplateId?: string | number
  data?: object
}) => {
  return await dponetAPI.post(
    `/advisors/data_processes/templates/${dataProcessTemplateId}/companies`,
    data
  )
}

export default {
  get,
  create,
}
