import { Grid } from '@mui/material'

import { DefaultPageStructure, PageHead } from 'components'
import { Form, Mapping, LinkedCompanies } from './components'

const DataProcessTemplateNew = () => {
  return (
    <DefaultPageStructure>
      <PageHead name="Novo Modelo" />

      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Form form="create" />
        </Grid>

        <Grid item xs={12}>
          <Mapping form="create" />
        </Grid>

        <Grid item xs={12}>
          <LinkedCompanies form="create" />
        </Grid>
      </Grid>
    </DefaultPageStructure>
  )
}

export default DataProcessTemplateNew
