import { Box, styled } from '@mui/material'

import BlueHexagon from 'images/blue_hexagon.png'

const HexagonBox = styled(Box)(() => ({
  backgroundImage: `url(${BlueHexagon})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
}))

export default HexagonBox
