import api from 'service/api'

import { AUTH_TOKEN, ORGANIZATION_TOKEN } from 'service/constants'

const dponetAPI = api.create('dponet', false)

const login = async (data: object) => {
  const loginResponse = await dponetAPI.post('/advisors/authentication', data)

  if (loginResponse?.data?.authToken) {
    const token = loginResponse.data.authToken

    setToken(token)
    return loginResponse
  }

  if (loginResponse?.data?.accessToken) {
    return loginResponse
  }

  throw 'Erro na autenticação'
}

const logout = () => {
  removeToken()
  removeOrganization()
}

const createAccount = async (data: object) =>
  await dponetAPI.post('/advisors/register', data)

const recoveryPassword = async (data: object) =>
  await dponetAPI.post('/advisors/users/password', { apiUser: data })

const newPassword = async (data: object) =>
  await dponetAPI.put('/advisors/users/password', { apiUser: data })

const setToken = (token: string) => {
  localStorage.setItem(AUTH_TOKEN, token)
}

const setOrganization = (id?: number | string) => {
  localStorage.setItem(ORGANIZATION_TOKEN, id?.toString() || '')
}

const removeOrganization = () => {
  localStorage.removeItem(ORGANIZATION_TOKEN)
}

const getToken = () => {
  return localStorage.getItem(AUTH_TOKEN)
}

const getOrganization = () => {
  return localStorage.getItem(ORGANIZATION_TOKEN)
}

const removeToken = () => {
  localStorage.removeItem(AUTH_TOKEN)
}

const isAuthenticated = () => {
  const token = getToken()
  return !!token
}

export default {
  createAccount,
  getOrganization,
  getToken,
  isAuthenticated,
  login,
  logout,
  newPassword,
  recoveryPassword,
  removeOrganization,
  setOrganization,
  setToken,
}
