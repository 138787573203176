import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material'

import { Card, LabelDescription } from 'components'
import { Questions } from 'views/DataProcessTemplateQuestion/components'

const GeneralInformationStep = () => {
  return (
    <Card title="Informações Gerais">
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant="body1" color="text.secondary">
            Preencha os campos de informações gerais do tratamento de dados
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <LabelDescription
            title="Departamentos"
            description="Selecione o departamento em que os dados serão armazenados"
          />
          <Select fullWidth disabled variant="outlined" value="" />
        </Grid>
        <Grid item xs={12} md={6}>
          <LabelDescription
            title="Código"
            description="Identificador único do registro em sua empresa"
          />
          <TextField fullWidth disabled variant="outlined" />
        </Grid>
        <Grid item xs={12} md={6}>
          <LabelDescription
            title="Versão"
            description="Versão do novo processamento de dados."
          />
          <TextField fullWidth disabled variant="outlined" />
        </Grid>
        <Grid item xs={12}>
          <LabelDescription
            title="Nome do processo"
            description="Descreva qual o nome do processo que vamos mapear, ou seja, aqui é onde damos início ao mapeamento"
          />
          <TextField fullWidth disabled variant="outlined" />
        </Grid>
        <Grid item xs={12}>
          <LabelDescription
            title="De onde vêm os dados? (origem)"
            description="Descreva qual a origem dos dados?"
          />
          <Box display="flex" gap={4}>
            <Select fullWidth disabled variant="outlined" value="" />
            <Box display="flex" alignItems="center" justifyContent="center">
              <Button variant="contained" color="primary">
                Adicionar
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <LabelDescription
            title="Por que  dado é tratado?"
            description="Para que? Por qual motivo a empresa trata esses dados? O objetivo é descobrir a finalidade"
          />
          <TextField fullWidth disabled variant="outlined" />
        </Grid>
        <Grid item xs={12}>
          <LabelDescription
            title="Tempo de armazenamento na organização"
            description="Por quanto tempo os dados ficam armazenados na organização?"
          />
          <FormControl>
            <RadioGroup value="">
              <FormControlLabel control={<Radio />} label="Definido" />
              <FormControlLabel control={<Radio />} label="Indefinido" />
              <FormControlLabel control={<Radio />} label="Permanente" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Questions step="general_information" />
        </Grid>
      </Grid>
    </Card>
  )
}

export default GeneralInformationStep
