import { TableRow as TableRowMUI } from '@mui/material'

import { TableRowType } from './tableRow.types'

const TableRow = ({
  onClick = () => {},
  hover = true,
  children,
}: TableRowType) => {
  return (
    <TableRowMUI hover={hover} onClick={onClick}>
      {children}
    </TableRowMUI>
  )
}

export default TableRow
