import { Grid, Skeleton, Box } from '@mui/material'
import { useParams } from 'react-router-dom'

import { DefaultPageStructure, PageHead } from 'components'
import { Form, Mapping, LinkedCompanies } from './components'

import { DataProcessTemplateType } from 'types/dataProcessTemplate.types'

import { useFetch } from 'hooks'

import service from 'service'

const DataProcessTemplateEdit = () => {
  const { dataProcessTemplateId } = useParams()

  const { response, loading } = useFetch(
    service.dponet.dataProcessTemplate.get,
    {
      dataProcessTemplateId,
      params: {
        minimal: true,
      },
    }
  )

  const dataProcessTemplate: DataProcessTemplateType =
    response?.data?.dataProcessTemplate

  return (
    <DefaultPageStructure>
      <PageHead name="Edição do Modelo" />

      {loading ? (
        <Box gap={4} display="flex" flexDirection="column">
          <Skeleton height={200} variant="rounded" />
          <Skeleton height={200} variant="rounded" />
          <Skeleton height={200} variant="rounded" />
        </Box>
      ) : (
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Form dataProcessTemplate={dataProcessTemplate} form="edit" />
          </Grid>

          <Grid item xs={12}>
            <Mapping dataProcessTemplate={dataProcessTemplate} form="edit" />
          </Grid>

          <Grid item xs={12}>
            <LinkedCompanies
              dataProcessTemplate={dataProcessTemplate}
              form="edit"
            />
          </Grid>
        </Grid>
      )}
    </DefaultPageStructure>
  )
}

export default DataProcessTemplateEdit
