import { useState } from 'react'
import { Grid } from '@mui/material'

import { BaseBox, FormRegister } from './components'

const Register = () => {
  const [isLoading, setLoading] = useState<boolean>(false)

  return (
    <BaseBox title="Criar conta">
      <Grid item xs={12}>
        <FormRegister isLoading={isLoading} setLoading={setLoading} />
      </Grid>
    </BaseBox>
  )
}

export default Register
