import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@mui/material'

import { Card, LabelDescription } from 'components'
import { Questions } from 'views/DataProcessTemplateQuestion/components'

const LifeCycleStep = () => {
  return (
    <Card title="Salvaguarda e ciclo de vida">
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <LabelDescription
            title="Onde os dados são armazenados?"
            description="Selecione o local em que os dados são armazenados."
          />
          <Select fullWidth disabled variant="outlined" value="" />
        </Grid>
        <Grid item xs={12} md={6}>
          <LabelDescription
            title="Ambiente de alocação"
            description="A informação é alocada em que ambiente?"
          />
          <Select fullWidth disabled variant="outlined" value="" />
        </Grid>
        <Grid item xs={12}>
          <LabelDescription
            title="Tempo de armazenamento"
            description="Por quanto tempo esses dadaos serão armazenados?"
          />
          <FormControl>
            <RadioGroup value="">
              <FormControlLabel control={<Radio />} label="Definido" />
              <FormControlLabel control={<Radio />} label="Indefinido" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <LabelDescription
            title="Justificativa de retenção"
            description="Justifique porque o registro é retido no departamento pelo tempo informado."
          />
          <TextField
            fullWidth
            disabled
            variant="outlined"
            multiline
            minRows={3}
          />
        </Grid>
        <Grid item xs={12}>
          <LabelDescription
            title="Como os dados são dispostos?"
            description="Modo que os dados são dispostos após a utilização."
          />
          <Select fullWidth disabled variant="outlined" value="" />
        </Grid>
        <Grid item xs={12} md={6}>
          <LabelDescription
            title="Forma de recuperação"
            description="Qual dado é utilizado para localizar e acessar/recuperar o registro?"
          />
          <Select fullWidth disabled variant="outlined" value="" />
        </Grid>
        <Grid item xs={12} md={6}>
          <LabelDescription
            title="Forma de proteção"
            description="A informação é pretegida fisicamente ou eletronicamente?"
          />
          <Select fullWidth disabled variant="outlined" value="" />
        </Grid>
        <Grid item xs={12}>
          <Questions step="life_cycle" />
        </Grid>
      </Grid>
    </Card>
  )
}

export default LifeCycleStep
