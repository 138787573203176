import { Fragment, useState } from 'react'
import { Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { BasicDialog, LoadingFeedback } from 'components'

import { useSnackbar } from 'hooks'
import { ConfirmDestroyTemplateType } from './confirmDestroyTemplate.types'
import service from 'service'
import { formatters } from 'helpers'
import routes from 'constants/routes'

const ConfirmDestroyTemplate = ({
  open,
  onClose,
  dataProcessTemplateId,
}: ConfirmDestroyTemplateType) => {
  const { snackbar } = useSnackbar()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  const destroyTemplate = async () => {
    setLoading(true)
    try {
      await service.dponet.dataProcessTemplate.destroy({
        dataProcessTemplateId,
      })
      setLoading(false)
      navigate(routes.app.dataProcesses.templates.all)
    } catch (error) {
      snackbar.open({
        message: formatters.errorMessage(error, false),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Fragment>
      <LoadingFeedback open={loading} />
      <BasicDialog
        open={open}
        onClose={onClose}
        onSave={destroyTemplate}
        title="Tem certeza que deseja excluir este modelo?"
      >
        <Typography variant="body1" color="text.secondary">
          Caso haja informações preenchidas pelo usuário, elas serão perdidas
          permanentemente e não poderão ser recuperadas.
        </Typography>
      </BasicDialog>
    </Fragment>
  )
}

export default ConfirmDestroyTemplate
