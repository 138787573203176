import {
  Button,
  Paper,
  TableBody,
  TableContainer,
  TableHead,
} from '@mui/material'
import { ArrowRight as ArrowRightIcon } from 'react-feather'
import { useNavigate } from 'react-router-dom'
import { reverse } from 'named-urls'

import { MUI, Table as TableComponent, TableRow } from 'components'

import { DataProcessTemplateTableType } from './dataProcessTemplateTableType'

import { formatters } from 'helpers'
import routes from 'constants/routes'

const DataProcessTemplateTable = ({
  dataProcessTemplates,
}: DataProcessTemplateTableType) => {
  const navigate = useNavigate()

  const handleRedirectToEditPage = (dataProcessTemplateId: string) => {
    navigate(
      reverse(routes.app.dataProcesses.templates.edit, {
        dataProcessTemplateId,
      })
    )
  }
  return (
    <Paper>
      <TableContainer>
        <TableComponent aria-label="listagem de tickets">
          <TableHead>
            <MUI.TableCell align="left">Identificador</MUI.TableCell>
            <MUI.TableCell align="center">Nome do Modelo</MUI.TableCell>
            <MUI.TableCell align="center">Responsável</MUI.TableCell>
            <MUI.TableCell align="center">Última atualização</MUI.TableCell>
            <MUI.TableCell align="center">Empresas vinculadas</MUI.TableCell>
            <MUI.TableCell align="center" width={10}>
              Ações
            </MUI.TableCell>
          </TableHead>
          <TableBody>
            {dataProcessTemplates.map((dataProcessTemplate) => (
              <TableRow
                key={dataProcessTemplate.id}
                onClick={() => handleRedirectToEditPage(dataProcessTemplate.id)}
              >
                <MUI.TableCell>{dataProcessTemplate.id}</MUI.TableCell>
                <MUI.TableCell align="center">
                  {dataProcessTemplate.name}
                </MUI.TableCell>
                <MUI.TableCell align="center">
                  {dataProcessTemplate?.user?.name || '-'}
                </MUI.TableCell>
                <MUI.TableCell align="center">
                  {formatters.date.ptBrFormatDate(
                    dataProcessTemplate?.updatedAt
                  )}
                </MUI.TableCell>
                <MUI.TableCell align="center">
                  {dataProcessTemplate?.totalLinkedCompanies}
                </MUI.TableCell>
                <MUI.TableCell align="center">
                  <Button
                    variant="outlined"
                    color="neutral"
                    endIcon={<ArrowRightIcon size={20} />}
                    onClick={() =>
                      handleRedirectToEditPage(dataProcessTemplate.id)
                    }
                  >
                    DETALHES
                  </Button>
                </MUI.TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TableComponent>
      </TableContainer>
    </Paper>
  )
}

export default DataProcessTemplateTable
