import { Typography } from '@mui/material'

import { BasicDialog } from 'components'

import { DeleteFieldDialogTypes } from './deleteFieldDialog.types'

const DeleteFieldDialog = ({
  open,
  onClose,
  onSave,
}: DeleteFieldDialogTypes) => {
  return (
    <BasicDialog
      open={open}
      title="Tem certeza que deseja excluir este campo?"
      onClose={onClose}
      onSave={onSave}
    >
      <Typography variant="body1" color="text.secondary">
        Ao excluir este campo, ele será removido de todos os processos em que
        estiver presente. Caso haja informações preenchidas pelo usuário, elas
        serão perdidas permanentemente e não poderão ser recuperadas
      </Typography>
    </BasicDialog>
  )
}

export default DeleteFieldDialog
