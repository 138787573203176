import { Box, Button, Grid, Hidden } from '@mui/material'

import {
  GeneralInformationStep,
  DataCollectedStep,
  DataTreatmentStep,
  LifeCycleStep,
  Stepper,
} from './components'

import { useDataProcessQuestionTemplate } from 'hooks'
import Container from '../Container'

const Steps = () => {
  const { activeStep, handleBack, handleNext } =
    useDataProcessQuestionTemplate()

  const steps = [
    { component: <GeneralInformationStep /> },
    { component: <DataCollectedStep /> },
    { component: <LifeCycleStep /> },
    { component: <DataTreatmentStep /> },
  ]

  return (
    <Container container spacing={4}>
      <Grid item xl={8} lg={8} md={12} xs={12}>
        {steps[activeStep].component}
        <Hidden lgUp>
          <Box display="flex" gap={2} justifyContent="flex-end" mt={2}>
            {activeStep > 0 && (
              <Button variant="outlined" onClick={handleBack}>
                Voltar
              </Button>
            )}
            <Button variant="contained" onClick={handleNext}>
              {activeStep === 3 ? 'Finalizar' : 'Próximo'}
            </Button>
          </Box>
        </Hidden>
      </Grid>
      <Grid item xl={4} lg={4} md={12} xs={12}>
        <Stepper />
      </Grid>
    </Container>
  )
}

export default Steps
