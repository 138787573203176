import { Box, styled } from '@mui/material'
import GreenHexagon from 'images/step_green.png'
import BlueHexagon from 'images/step_blue.png'
import X from 'images/step_x.png'

import { HexagonBoxType } from './HexagonBox.types'

const HexagonBox = styled(Box)<HexagonBoxType>(({ typehexagon }) => ({
  backgroundImage:
    typehexagon === 'blueHexagon'
      ? `url(${BlueHexagon})`
      : typehexagon === 'greenHexagon'
      ? `url(${GreenHexagon})`
      : `url(${X})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
}))

export default HexagonBox
